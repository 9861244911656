<template>
  <div class="login_container">
    <div class="FormBox bbox">
      <el-form
        ref="loginFormRef"
        :model="loginForm"
        :rules="loginFormRules"
        label-width="0"
        class="login_form"
      >
        <div class="mt30 mb50 flex align-center justify-between">
          <span class="text333 f20 fw700 flex1">{{ $t("title") }} </span>
          <LangSelect></LangSelect>
        </div>
        <!-- 账号 -->
        <el-form-item prop="phone">
          <el-input
            :placeholder="$t('login.phone')"
            v-model="loginForm.phone"
            maxlength="11"
            clearable
          >
          </el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="password">
          <el-input
            v-model="loginForm.password"
            type="password"
            :placeholder="$t('login.password')"
            clearable
            show-password
          >
          </el-input>
        </el-form-item>
        <el-form-item class="mt10">
          <el-checkbox v-model="loginForm.checked" class="f16">
            {{ $t("login.keepPwd") }}</el-checkbox
          >
        </el-form-item>
        <!-- 登录按钮 -->
        <el-form-item class="btns mt50">
          <el-button type="primary" @click.stop="login" class="f16 fw700">{{
            $t("login.logIn")
          }}</el-button>
        </el-form-item>
        <!-- 注册和忘记密码 -->
        <div class="flex justify-between textblue f14 mt30">
          <span class="pointer" @click="jump('/regInstructions')">{{
            $t("login.reg")
          }}</span>
          <span class="pointer" @click="gotoForgetPwd">{{
            $t("login.lostPwd")
          }}</span>
        </div>
        <!-- 用户协议 -->
        <!-- <div class="positionf agreeMents">
          <div class="text999">
            <el-checkbox v-model="checked"></el-checkbox>
            {{$t("login.readTip")}}<span class="textblue">《{{$t("login.useManual")}}》</span>
          </div>
        </div> -->
      </el-form>
    </div>
  </div>
</template>

<script>
import LangSelect from "@/components/LangSelect";
import { Dialog } from "vant";
import { Toast } from "vant";
var tips1 = "手机号不能为空";
var tips2 = "手机号格式错误";
var tips3 = "请输入用户密码";
var tips4 = "长度在 4 到 18 个字符";
var tips5 = "请先阅读后勾选用户协议";
var topTitle = "提示";
var loginFailed = "登录失败:";
var tips6 = "由于您长时间未修改密码，存在安全隐患，请去个人中心修改密码";
var tips7 = "温馨提示";
export default {
  components: { LangSelect },
  data() {
    var validatorPhone = function (rule, value, callback) {
      if (value === "") {
        callback(new Error(tips1));
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error(tips2));
      } else {
        callback();
      }
    };
    var validatorPwd = function (rule, value, callback) {
      if (value === "") {
        callback(new Error(tips3));
      } else if (value.length < 4 || value.length > 18) {
        callback(new Error(tips4));
      } else {
        callback();
      }
    };
    return {
      noticeTime: 180,
      loginForm: {
        phone: "",
        password: "",
        checked: "",
      },
      checked: true,
      // 表单验证
      loginFormRules: {
        phone: [{ required: true, validator: validatorPhone, trigger: "blur" }],
        password: [
          { required: true, validator: validatorPwd, trigger: "blur" },
          {
            min: 4,
            max: 18,
            message: tips4,
            trigger: "blur",
          },
        ],
      },
      url: "",
      urlItem:""
    };
  },
  methods: {
    //跳转页面
    jump(path) {
      this.$router.push(path);
    },
    // 跳转到忘记密码页面
    gotoForgetPwd() {
      this.$router.push(
        { path: "/forgetPwd" },
        { phone: this.loginForm.phone }
      );
    },
    login() {
      // 表单预验证
      // valid：bool类型
      this.$refs.loginFormRef.validate((valid) => {
        if (valid) {
          if (this.checked == false) {
            Toast({
              message: tips5,
              position: "top",
            });
          } else {
            //调用登录接口
            var that = this;
            var member = {};
            member.phone = this.loginForm.phone;
            member.password = this.loginForm.password;
            this.$http.post("member/login", member).then(function (response) {
              if ("200" == response.data.code) {
                //保存登录信息
                var member = response.data.data;
                member.isLogin = true;
                localStorage.setItem("member", JSON.stringify(member));
                localStorage.setItem("memberCode", member.memberCode);

                //记住密码
                that.keepPwd();

                var updatePwdDt = member.updatePwdDt;
                //console.log("updatePwdDt======上次修改密码时间========"+updatePwdDt);
                var currTime = new Date();
                ///  console.log("currTime======当前时间========"+currTime);
                var time = currTime - updatePwdDt;
                // console.log("time======已经经过时间========"+time);
                var noticeTime =
                  parseInt(that.noticeTime) * 24 * 60 * 60 * 1000;
                //  console.log("noticeTime======提醒周期========"+noticeTime);
                if (time > noticeTime) {
                  Dialog.confirm({
                    title: tips7,
                    message: tips6,
                    confirmButtonText: that.$t("my.confirm"),
                    cancelButtonText: that.$t("my.cancle"),
                  })
                    .then(() => {
                      console.log("已超时");
                      that.$router.push({
                        path: "/accountInfo",
                      });
                    })
                    .catch(() => {
                      that.$router.push({
                        path: "/index",
                      });
                    });
                } else {
                  console.log("未超时");
                  // that.$router.push({
                  //   path: "/index",
                  // });
                  if (that.urlItem == "/" || that.urlItem == "/login") {
                    that.$router.push({
                      path: "/index",
                    });
                  } else {
                    window.location.href = that.url;
                    window.location.reload();
                  }
                }
              } else {
                that.$notify.error({
                  title: topTitle,
                  message: loginFailed + response.data.message,
                  showClose: true,
                });
              }
            });
          }
        }
      });
    },
    keepPwd() {
      if (this.loginForm.checked) {
        localStorage.setItem("isKeepPwd", "1");
        localStorage.setItem("akl_account", this.loginForm.phone);
        localStorage.setItem("akl_pwd", this.loginForm.password);
      } else {
        localStorage.removeItem("isKeepPwd");
        localStorage.removeItem("akl_account");
        localStorage.removeItem("akl_pwd");
      }
    },
    /**
     * 切换语言
     */
    changeLanguage() {
      if (this.$store.getters.language == "zh") {
        tips1 = "手机号不能为空";
        tips2 = "手机号格式错误";
        tips3 = "请输入用户密码";
        tips4 = "长度在 4 到 18 个字符";
        tips5 = "请先阅读后勾选用户协议";
        topTitle = "提示";
        loginFailed = "登录失败:";
        tips6 = "由于您长时间未修改密码，存在安全隐患，请去个人中心修改密码";
        tips7 = "温馨提示";
      }
      if (this.$store.getters.language == "en") {
        tips1 = "The phone number cannot be blank";
        tips2 = "The format of mobile phone number is wrong";
        tips3 = "Please enter user password";
        tips4 = "The length is between 4 and 18 characters";
        tips5 = "Please read the User Agreement before checking it";
        topTitle = "prompt";
        loginFailed = "Login failed:";
        tips6 =
          "Because you have not changed your password for a long time, there is a security risk, please go to the personal center to change the password";
        tips7 = "Warm prompt";
      }
      if (this.$store.getters.language == "ja") {
        tips1 = "携帯電話番号は空欄にしてはいけません";
        tips2 = "携帯番号のフォーマットが間違っています";
        tips3 = "ユーザーパスワードを入力してください";
        tips4 = "長さは4 ~ 18文字です";
        tips5 = "ユーザー契約書にチェックを入れてください";
        topTitle = "提示";
        loginFailed = "ログインに失敗する:";
        tips6 =
          "長期間パスワードを変更していないため、セキュリティに問題がありますので、個人センターでパスワードを変更してください";
        tips7 = "暖かいヒント";
      }
    },

    //获取修改密码周期
    updatePwdTime() {
      var that = this;
      var params = {};
      this.$http
        .post("systemConfig/getUpdatePwdTime", params)
        .then(function (response) {
          if ("200" == response.data.code) {
            var noticeTime = response.data.data;
            that.noticeTime = noticeTime;
            console.log(that.noticeTime);
          }
        });
    },
  },
  created() {
     this.url = localStorage.getItem("href");
    this.urlItem = localStorage.getItem("hrefItem");
    this.changeLanguage();
    this.updatePwdTime();

    //记住密码
    var isKeepPwd = localStorage.getItem("isKeepPwd");
    if ("1" == isKeepPwd) {
      this.loginForm.checked = true;
      this.loginForm.phone = localStorage.getItem("akl_account");
      this.loginForm.password = localStorage.getItem("akl_pwd");
    }
  },
  mounted() {
    this.changeLanguage();
  },
  updated() {
    this.changeLanguage();
  },
};
</script>

<style scoped>
.login_container {
  height: 100vh;
  width: 100%;
}
.FormBox {
  padding: 30px;
}

.btns .el-button {
  display: block;
  width: calc(100vw - 60px);
}
.agreeMents {
  width: 100%;
  text-align: center;
  bottom: 30px;
  left: 0;
}
</style>
